<svelte:options customElement={{ tag: "bt-add-to-curation-modal", shadow: "none", }} />

<script lang="ts">
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { fly } from 'svelte/transition';
  import { default as CurationCheckbox } from '@/components/curations/CurationCheckbox.svelte';
  import { default as NewCurationItem } from '@/components/curations/NewCurationItem.svelte';
  import ModalToggle from '$shared/modal-toggle/ModalToggle.svelte';
  import Modal from '$shared/modal/Modal.svelte';

  export let contentId;
  export let contentType;

  let curations = writable([]);
  let itemToCurate;
  
  const fetchCurationData = () => {
    const url = '/curations/add_to_curation';
    const curationData = {
      "curated_item": contentId,
      "content_type": contentType
    }

    fetch(url, {
      body: JSON.stringify(curationData),
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      }
    })
    .then(response => response.json())
    .then(data => {
      curations = JSON.parse(JSON.stringify(data.collection));
      itemToCurate = JSON.parse(JSON.stringify(curationData));
    });
  }

  onMount(() => {
    fetchCurationData();
  });

	const defaultMessage = '';
	let feedbackMessage = defaultMessage;
	let closeMessageTimeout = setTimeout(clearMessage, 2000);

  async function addItemToCuration(curationId: string) {
    const updateCurationData = {
      "curatable_id": itemToCurate.curated_item,
      "curatable_type": itemToCurate.content_type
    }

    try {
      await fetch(`/curations/${curationId}/curated_items`, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify(updateCurationData)
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function createNewCuration(name: string) {
    const newCurationData = {
      "curation": {
        "name": name
      }
    }

    try {
      const response = await fetch(`/curations`, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify(newCurationData),
      });

      const data = await response.json();
      const curationId = JSON.stringify(data.curation_id);

      return curationId;
    } catch (error) {
      console.error(error);
    }
	}

  function insertNewCuration(curationId: string, name: string) {
    const newCuration = {
      "id": parseInt(curationId),
      "name": name,
      "existing_id": parseInt(curationId)
    }
    curations = [newCuration, ...curations];
  }

	function handleAddToCuration(e: any) {
		let curationId = e.detail.id;

    addItemToCuration(curationId);

    setMessage('Added to curation');
	}

	function setMessage(msg: string) {
		feedbackMessage = msg;
		clearTimeout(closeMessageTimeout);
		closeMessageTimeout = setTimeout(clearMessage, 2000);
	}

	function clearMessage() {
		feedbackMessage = defaultMessage;
	}

	function handleRemoveFromCuration(e: any) {
		let curationId = e.detail.id;
    let curationItemId = e.detail.item_id;

    fetch(`/curations/${curationId}/curated_items/${curationItemId}`, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      }
    });

		setMessage('Removed from curation');
	}

	async function handleSubmit(e: any) {
    try {
      const curationId = await createNewCuration(e.detail.value);

      await addItemToCuration(curationId);

      setMessage('New curation created');

      insertNewCuration(curationId, e.detail.value);
    } catch (error) {
      console.error(error);
    }
	}
</script>

{#if curations && itemToCurate}
  <Modal modalTitle="Add to Curation" closeButtonText="Close">
    <div class="flex flex-col gap-4" slot="modal-contents">
      <NewCurationItem on:newCurationSubmit={handleSubmit} />
      <div class="relative">
        <div class="flex flex-col gap-2 h-full max-h-40 overflow-scroll border p-2">
          {#each curations as curation (curation.id)}
            <CurationCheckbox
              on:check={handleAddToCuration}
              on:uncheck={handleRemoveFromCuration}
              allCurations={curations}
              curation={curation}
              i={curation.id}
            />
          {/each}
        </div>
      </div>
      <div class="flex">
        <div class="flex items-center opacity-50 italic transition-all relative w-full">
          {#key feedbackMessage}
            <p class="absolute left-0" in:fly={{ y: 20 }} out:fly={{ y: -20 }}>{feedbackMessage}</p>
          {/key}
        </div>
      </div>
    </div>
  </Modal>
{/if}