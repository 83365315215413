import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static targets = ["prompt"];
  static values = {
    div: String,
    mediaid: String,
    completion: Number,
    language: String,
    scorm: Boolean,
    scorm_content_id: Number,
    scorm_content_type: String,
  };

  connect() {
    const controller = this;

    controller.lastLogged = -1;
    controller.loggedCompletion = false;

    controller.playlist_url = `https://cdn.jwplayer.com/v2/media/${controller.mediaidValue}`;
    controller.player = jwplayer(controller.divValue).setup({
      playlist: controller.playlist_url,
      floating: {
        dismissible: true,
      },
    });
    controller.player.on("ready", function (event) {
      controller.dispatch("prompt", {
        detail: {
          player_id: controller.divValue,
          time: 0,
        },
      });
      if (controller.languageValue) {
        const captions = controller.player.getCaptionsList();
        const langIndex = captions.findIndex(item => item.language === controller.languageValue);
        if (langIndex > 0) {
          controller.player.setCurrentCaptions(langIndex);
        }
      }
    });
    controller.player.on("time", function (event) {
      controller.progress(controller, event);
    });
  }

  progress(controller, event) {
    const currentPosition = event.position;
    const totalDuration = event.duration;
    const percentComplete = Math.round((currentPosition / totalDuration) * 100);

    controller.dispatch("prompt", {
      detail: {
        player_id: controller.divValue,
        time: Math.floor(event.currentTime),
      },
    });

    if (controller.lastLogged !== percentComplete) {
      console.log(percentComplete);
      controller.lastLogged = percentComplete;
      ahoy.track("videos.progress", {
        percent: percentComplete,
        id: controller.divValue,
      });
    }

    if (
      controller.scormValue &&
      controller.loggedCompletion !== true &&
      percentComplete >= controller.completionValue
    ) {
      controller.loggedCompletion = true;
      // send completion to LMS, by dispatching an event that the
      // scorm_controller will listen for
      document.dispatchEvent(
        new CustomEvent("scorm.complete", {
          detail: {
            content_id: controller.scormContentIdValue,
            content_type: controller.scormContentTypeValue,
          },
        })
      );
    }
  }
}
