<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { readable } from 'svelte/store';
  import { Render, Subscribe, createTable } from 'svelte-headless-table';
  import { addPagination, addSortBy } from 'svelte-headless-table/plugins';
  import { debounce } from '$shared/lib/debounce';

  import * as Table from '$lib/components/ui/table';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import * as Select from '$lib/components/ui/select';
  import { Button } from '$lib/components/ui/button';
  import { Input } from '$lib/components/ui/input';

  import DataTableColumnHeader from '$shared/data-table/data-table-column-header.svelte';

  const dispatch = createEventDispatcher();

  export let columnData: any = [];
  export let data: any[] = [];
  export let filterColumnNames: string[] = [];
  export let table: any;
  export let tableName: string = "";
  export let toolTips: any;

  let sortKey: any = {};

  // if there is data in the filterColumnNames prop, use that, with commas and
  // an "or" before the last item. Otherwise, use the default of "Filter..."
  $: formattedFilterColumnNames = filterColumnNames
    .reduce((acc, curr, index) => {
      if (index === 0) {
        return curr;
      } else if (index === filterColumnNames.length - 1) {
        return `${acc} or ${curr}`;
      } else {
        return `${acc}, ${curr}`;
      }
    }, '');
  $: filterPlaceholder =
    (formattedFilterColumnNames && `Filter by ${formattedFilterColumnNames}...`)
      || 'Filter...';
  $: filterText = '';
  $: table = createTable(readable(data), {
    page: addPagination(),
    sort: addSortBy({
      disableMultiSort: true,
      serverSide: true,
      toggleOrder: ['desc', 'asc', undefined]
    }),
  });

  // the following depend on the table
  $: columns = table.createColumns(columnData);
  $: ({ headerRows, pageRows, tableAttrs, tableBodyAttrs, pluginStates } =
    table.createViewModel(columns));

  // the following depend on the plugin states
  $: ({ hasNextPage, hasPreviousPage, pageIndex, pageCount } =
    pluginStates.page);
  $: ({ sortKeys } = pluginStates.sort);

  $: sortKeys.subscribe((value) => {
    // this gets called multiple times when the sortKeys change, so we need to
    // make sure that the value is different from the current sortKey before
    // updating the sortKey. Otherwise, we'll end up with an infinite loop.
    // The value is an array of sortKeys, but we only want the first one,
    // because we're only allowing one column to be sorted at a time.
    // The sortKey is an object with an id and an order, so we need to compare
    // the id and the order separately.
    if (value.length <= 0) return;
    if (value[0].id === sortKey.id && value[0].order === sortKey.order) return;

    sortKey = value[0];
    debouncedFilterData();
  });

  function exportCsv() {
    dispatch('exportCsv', { filterText, sortKey });
  }

  function filterData() {
    dispatch('filterData', { filterText, sortKey });
  }
  const debouncedFilterData = debounce(filterData, 300);
</script>

<div class="space-y-4">
  <div class="flex md:flex-row flex-col gap-2 justify-between items-end">
    <div class="flex flex-col w-full gap-2">
      <p class="text-sm opacity-50 font-bold">{tableName}</p>
      <div class="flex space-x-2">
        <Input
          class="md:max-w-sm w-full"
          placeholder={filterPlaceholder}
          type="text"
          bind:value={filterText}
          on:keyup={debouncedFilterData}
        />
      </div>
    </div>
    <div class="w-full flex items-center justify-end space-x-2">
      <Button variant="outline" size="default" on:click={exportCsv}>Download</Button>
    </div>
  </div>
  <div class="rounded-md border border-neutral-200 bg-white">
    <Table.Root {...$tableAttrs}>
      <Table.Header>
        {#each $headerRows as headerRow}
          <Subscribe rowAttrs={headerRow.attrs()}>
            <Table.Row>
              {#each headerRow.cells as cell (cell.id)}
                <Subscribe attrs={cell.attrs()} let:attrs props={cell.props()} let:props>
                  <Table.Head {...attrs}>
                    {#if cell.id !== 'select' && cell.id !== 'actions'}
                      <DataTableColumnHeader {props}>
                        <Tooltip.Root>
                          <Tooltip.Trigger>
                            <Render of={cell.render()} />
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            <p>{toolTips[cell.id.toString()]}</p>
                          </Tooltip.Content>
                        </Tooltip.Root>
                      </DataTableColumnHeader>
                    {:else}
                      <Render of={cell.render()} />
                    {/if}
                  </Table.Head>
                </Subscribe>
              {/each}
            </Table.Row>
          </Subscribe>
        {/each}
      </Table.Header>
      <Table.Body {...$tableBodyAttrs}>
        {#each $pageRows as row (row.id)}
          <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
            <Table.Row {...rowAttrs}>
              {#each row.cells as cell (cell.id)}
                <Subscribe attrs={cell.attrs()} let:attrs>
                  <Table.Cell {...attrs}>
                    {#if cell.id === 'amount'}
                      <div class="text-right font-normal">
                        <Render of={cell.render()} />
                      </div>
                      {:else if cell.id === 'status'}
                      <div class="capitalize">
                        <Render of={cell.render()} />
                      </div>
                    {:else}
                      <Render of={cell.render()} />
                    {/if}
                  </Table.Cell>
                </Subscribe>
              {/each}
            </Table.Row>
          </Subscribe>
        {/each}
      </Table.Body>
    </Table.Root>
  </div>
  <div class="flex justify-between">
    <div class="">
      <p class="text-sm">{data.length} rows</p>
    </div>
    <div class="flex items-center justify-end space-x-2">
      {#if pageCount > 0}
        <div class="">
          <p class="text-sm">Page {$pageIndex + 1} of {$pageCount}</p>
        </div>
      {/if}
      <Button
        variant="outline"
        size="sm"
        class="select-none"
        on:click={() => ($pageIndex = $pageIndex - 1)}
        disabled={!$hasPreviousPage}>Previous</Button
      >
      <Button
        variant="outline"
        class="select-none"
        size="sm"
        disabled={!$hasNextPage}
        on:click={() => ($pageIndex = $pageIndex + 1)}>Next</Button
      >
    </div>
  </div>
</div>
