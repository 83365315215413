import { mapRange } from 'fractils';

export const columnHeaders: any = {
	createdAt: 'Created At',
	updatedAt: 'Updated At',
	email: 'Email',
	name: 'Name',
	lastActive: 'Last Active',
	views: 'Views',
	completionRate: 'Completion Rate',
	totalViewTime: 'Total View Time',
	creator: 'Creator',
	duration: 'Duration',
	activeUsers: 'Active Users',
	capability: 'Capability',
	timeWatched: 'Time Watched',
	video_lesson_name: 'Video Lesson Name',
	video_duration: 'Video Duration',
	total_learners: 'Total Learners',
	primary_capability: 'Primary Capability',
	video_last_activity: 'Last Activity',
	total_video_viewed_seconds: 'View Time (sec)',
	total_video_lessons_started: 'Total Video Lessons Started',
	video_lesson_completion_rate: 'Completion Rate',
	num_curation_items_viewed: 'Total Item Views',
	aasm_state: 'Status',
	duration_in_seconds: 'Duration (hh:mm:ss)',
	type: 'Type',
	total_views: 'Total Views',
	total_viewed_seconds: 'Total View Time',
	completion_rate: 'Completion Rate',
	last_activity: 'Last Activity',
	user_created_at: 'Created At',
	user_last_activity: 'Last Activity',
	total_non_video_lessons_viewed: 'Total Non-Video Lessons Viewed',
	user_expires_at: 'Expires At'
};

export const userMgmtColumnHeaders: any = {
	email: 'Email',
	expires_at: 'Expires At',
	full_name: 'Name',
	roles: 'Permissions',
	status: 'Status',
	updated_at: 'Last Activity'
};

export const userMgmtWithCompanyColumnHeaders: any = {
	...userMgmtColumnHeaders,
	company: 'Company'
}

export const toolTips: any = {
	createdAt: 'Created at date',
	updatedAt: 'Last time on BT+',
	email: 'Email address of xyz',
	name: 'Name of xyz',
	lastActive: 'Last time on BT+',
	views: 'Total number views per date range',
	completionRate: 'Average completion Rate',
	totalViewTime: 'Total view time of item',
	undefined: 'No tooltip available',
	aasm_state: 'Status of item'
};

export const userMgmtToolTips: any = {
	email: "User's email",
	expires_at: "Expiration date of the user, if applicable",
	full_name: "User's first and last name",
	roles: "User's permissions on the site",
	status: "User's status: Active, Expired, Hasn't Logged In",
	updated_at: "User invitation date or most recent login"
};


// list of columns to skip
export const skipList: string[] = [
	'avatar',
	'children',
  'id',
	'organization_id',
	'organization_name',
	'slug',
	'total_video_lessons_started',
	'total_video_lessons_completed',
  'url',
	'user_id',
	'video_id'
];

// list of columns to filter by using search field
export const filterList: string[] = [
  'email',
  'name',
  'object_name',
  'user_email',
  'user_name',
  'video_lesson_name'
];

export const userFilterList: string[] = ['email', 'full_name'];

export const noSortList: string[] = ['actions', 'select', 'actions2', 'expanded', 'roles'];

// formatted lists
const dateList = [
	'createdAt',
	'updatedAt',
	'lastActive',
	'video_last_activity',
	'last_activity',
	'user_created_at',
	'user_last_activity',
	'user_expires_at'
];
const secondsList = ['total_video_viewed_seconds', 'duration_in_seconds', 'total_viewed_seconds'];
const percentageList = ['completionRate', 'video_lesson_completion_rate', 'completion_rate'];

export function getFormattedValue(header: string, value: any) {
	if (dateList.includes(header)) {
		return getTimeAgo(value);
	}
	if (secondsList.includes(header)) {
		return getViewTime(value);
	}
	if (percentageList.includes(header)) {
		return getPercentage(value);
	}
	if (header === 'roles') {
		return getFormattedPermissions(value);
	}
	return value;
}

export function getViewTime(viewTimeString: string) {
	const parsed = parseFloat(viewTimeString);
	const mappedTime = mapRange(parsed, 0, 100000, 0, 2060);

	const num = mappedTime;
	const hours = num / 60;
	const rhours = Math.floor(hours);
	const minutes = (hours - rhours) * 60;
	const rminutes = Math.round(minutes);
	if (rhours == 0) return rminutes + ` min${rminutes > 1 ? 's' : ''}`;
	return (
		rhours + ` hour${rhours > 1 ? 's' : ''} and ` + rminutes + ` min${rminutes > 1 ? 's' : ''}`
	);
}

export function getTimeAgo(dateString: string) {
	const date = new Date(dateString);
	const formatted = date.toLocaleDateString('en', {
		year: 'numeric',
		month: '2-digit',
		day: 'numeric'
	});

	const formattedTime = date.toLocaleTimeString('en', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true
	});
	return formattedTime + ' ' + formatted;
}

export function getPercentage(value: string) {
	const parsed = parseFloat(value) * 100;
	return parsed.toFixed(2) + '%';
}

export function getFormattedPermissions(value: string[]) {
  if (!value || value.length === 0) {
    return 'Learner';
  }

	if (value.includes('account_owner')) {
		return 'Administer Account & Users';
	}

  // if the value is a string, convert it to an array by splitting on commas
  if (typeof value === 'string') {
    value = value.split(',');
  }

  let roles = value.map((role) => {
    return getRoleName(role);
  });
  return roles.join(', ');
}

export function getRoleName(value: string) {
	if (value.includes('manage_comments')) {
		return 'Manage Comments';
	}
	if (value.includes('manage_curations')) {
		return 'Manage Curations';
	}
	if (value.includes('manage_users')) {
		return 'Manage Users';
	}
	if (value.includes('view_analytics')) {
		return 'View Analytics';
	}
}
