<script lang="ts">
  import { onMount } from 'svelte';
  import { createRender } from 'svelte-headless-table';

  import { dateStore } from '$comp/analytics/dateStore';
  import DataTable from '$shared/data-table/data-table.svelte';
  import DataTableActions from './data-table-actions-analytics.svelte';
  import { filterList, skipList } from '$shared/data-table/tableHelpers';

  export let data_url: string = "";
  export let loading: number = 0;
  export let tableName: string = "";

  let table: any;

  let cols: any[] = [];
  let data: any[] = [];

  let columnHeaders: any[] = [];
  let toolTips: any = {};

  let filterText: string = "";
  let sortKey: any = {};
  let startDate: string = "";
  let endDate: string = "";

  function createColumns() {
    for (const obj of columnHeaders) {
      for (const [key, value] of Object.entries(obj)) {
        if (skipList.includes(key)) continue;
        if (filterList.includes(key)) {
          cols.push(
            table.column({header: value, accessor: key})
          );
        } else {
          cols.push(
            table.column({
              header: value,
              accessor: key,
              plugins: {
                filter: {
                  exclude: true
                }
              }
            })
          );
        }
      }
    }

    if (showActions()) {
      cols.push(
        table.column({
          id: 'actions',
          header: 'Actions',
          cell: ({ row }: any) => {
            return createRender(DataTableActions, { row: row.original });
          }
        })
      );
    }
    cols = cols;
  }

  function handleExportCsv(event) {
    ({ filterText, sortKey } = event.detail);

    // if we tell the back-end that we want a csv, it will return the csv data
    // as a text blob. We can then create a link and click it to download the
    // csv file.
    loadData(true).then((remote_data) => {
      const blob = new Blob([remote_data], { type: 'text/csv' });
      const currentDate = new Date();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      let filename = `${tableName} ${currentDate.toISOString()}`;
      if (startDate && endDate) {
        filename += ` - ${startDate} to ${endDate}`;
      }
      if (filterText) {
        filename += ` - filtered to ${filterText}`;
      }

      a.href = url;
      a.download = `${filename}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  function handleFilterSortData(event) {
    ({ filterText, sortKey } = event.detail);

    loadData();
  }

  async function loadData(csv: boolean = false, callback: any = null) {
    if (loading > 0) return;

    let format = csv ? ".csv" : ".json";
    let query = "";

    if (filterText) {
      query = `?filter=${filterText}`;
    }
    if (sortKey) {
      query = query ?
        `${query}&sort=${sortKey.id}&order=${sortKey.order}` :
        `?sort=${sortKey.id}&order=${sortKey.order}`;
    }
    if (startDate && endDate) {
      query = query ?
        `${query}&start_date=${startDate}&end_date=${endDate}` :
        `?start_date=${startDate}&end_date=${endDate}`;
    }

    try {
      loading = 1;

      const url = `${data_url}${format}${query}`;
      const response = await fetch(url);

      // if the response is a csv, we can just return the text
      if (csv) return await response.text();

      // otherwise...
      const remote_data = await response.json();
      callback ? callback(remote_data) : parseRemoteData(remote_data);
    } catch(e) {
      console.error(e.message);
    } finally {
      loading = 0;
    }
  }

  function parseRemoteData(remote_data) {
    columnHeaders = remote_data.headers;
    data = remote_data.data;
    toolTips = remote_data.tooltips;

    if (columnHeaders.length > 0 && cols.length === 0) {
      createColumns();
    }
  }

  function showActions() {
    return data.length > 0 && "url" in data[0];
  }

  onMount(async () => {
    dateStore.subscribe(() => {
      startDate = dateStore.getStart();
      endDate = dateStore.getEnd();

      startDate && endDate && loadData();
    });
  });

  // return a flat array of the column names (the values of the columnHeaders
  // object) that are in the filterList
  $: filterColumnNames = columnHeaders.reduce((acc, curr) => {
    for (const [key, value] of Object.entries(curr)) {
      if (filterList.includes(key)) {
        acc.push(value);
      }
    }
    return acc;
  }, []);
</script>

<DataTable
  {data}
  {filterColumnNames}
  {toolTips}
  bind:table
  columnData={cols}
  on:exportCsv={handleExportCsv}
  on:filterData={handleFilterSortData}
  on:sortData={handleFilterSortData}
/>
