<script lang="ts">
	import { fade } from 'svelte/transition';
	import ToastItem from './ToastItem.svelte';
	import { toast, toasts } from './toastStore';

	import type { PositionType } from './types';

	export let position: PositionType = 'top-right';
	export let clearAfter = 4000;

	let heights = [0, 0, 0, 0, 0];
	let isHovering = false;
	let show = false;

	function setHeight(e: any) {
		let h = e.detail.height;
		let index = e.detail.index;
		heights[index] = h;
		heights = heights;
		resetToasts();
	}

	let timeout = setTimeout(() => {}, 3000);

	function resetToasts() {
		show = true;
		clearTimeout(timeout);
		if (isHovering) return;
		timeout = setTimeout(() => {
			toast.clear();
		}, clearAfter);
	}

	$: isHovering, resetToasts();
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
	out:fade|local={{ duration: 200 }}
	in:fade={{ duration: 200 }}
	class="block w-full fixed h-full z-50 pointer-events-none"
	on:mouseenter={() => (isHovering = true)}
	on:mouseleave={() => (isHovering = false)}
>
	{#each $toasts.slice(0).slice(-4).reverse() as item, i (item.id)}
		<ToastItem
			on:height={setHeight}
			title={item?.title}
			text={item?.description}
			status={item?.status}
			index={i}
			{position}
			stack={$toasts.slice(0).slice(-4).reverse().length - i}
			{isHovering}
			{heights}
		/>
	{/each}
</div>
