<svelte:options customElement={{ tag: "bt-user-management-wrapper", shadow: "none", }} />

<script lang="ts">
	import AddUser from './AddUser.svelte';
	import BulkRemove from './BulkRemove.svelte';
	import BulkUpload from './BulkUpload.svelte';
	import ResendEmail from './ResendEmail.svelte';
	import UserTable from './UserTable.svelte';

	export let companyOption: boolean = false;
	export let turnOffComments: boolean = false;
	export let railsData: string;
	export let tableName: string = 'User Management';
</script>

<UserTable {companyOption} {railsData} {tableName}>
	<ResendEmail />
	<BulkRemove />
	<BulkUpload />
	<AddUser {companyOption} {turnOffComments} />
</UserTable>

	<!-- 	unused rowActions for future refactor to pull row actions up to wrapper containers and drop to just one table
		rowActions:[
			{event: 'edit-user', title: 'Edit User'},
			{event: 'remove-user', title: 'Remove User'},
			{event: 'resend-invite', title: 'Resend Invite'}
		]
	-->

