<script lang="ts">
  import SvelteMarkdown from 'svelte-markdown'

  import Icon from '../shared/Icon.svelte';
  import Badge from '$shared/badge/Badge.svelte'
  import { toast } from '../shared/toastStore';

  export let content_count = 0;
  export let duration = '';
  export let editable: boolean = false;
  export let href = '/';
  export let id = 0;
  export let learning_objectives = '';
  export let learning_objectives_header = 'Learning Objectives';
  export let parent = 0;
  export let progress = '';
  export let subhead = '';
  export let subeyebrow = '';
  export let thumbnail_url = '';
  export let title = '';
  export let type_overlay = 'Lesson';
  export let updateable: boolean = false;

  function handleDelete(parent: any, id: any) {
    let curationId = parent;
    let curationItemId = id;

    fetch(`/curations/${curationId}/curated_items/${curationItemId}`, {
      method: "DELETE",
      credentials: "same-origin",
      // @ts-ignore
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        "X-CSRF-Token": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      }
    }).then(response => {
        if (response.ok) {
          const item = document.querySelector(`[data-id="${id}"]`);
          if (item) {
            item.remove();
            toast.add(title, { title: 'This item was deleted from your curation' });
          }
        }
    });
  }

  function getCurrentIndex(parent:any){
    if (!parent) return;
    return parent.toArray().indexOf(id.toString());
  }

</script>

<div data-id={id} data-parent={parent} class="relative flex flex-col gap-4 md:space-y-0 md:space-x-4 sm:flex-row mt-4 w-full md:flex-shrink-0 md:mt-0">
  <div class="absolute z-10 right-0 top-0 h-full flex items-start p-2">
    {#if updateable}
      <button
        on:click={() => handleDelete(parent, id)}
        class="w-7 h-7 bg-white shadow-md p-1.5 transition-all rounded handle"
        >
        <Icon icon="delete" tiny />
      </button>
    {/if}
  </div>
  <div class="w-full md:w-1/4">
    <div class="relative">
      <a href={href} class="relative block border border-neutral-350 hover:border-neutral-900 dark:hover:border-neutral-100 aspect-w-16 aspect-h-9">
        <img
          src={thumbnail_url}
          alt={title}
          class="flex flex-row transition-all duration-300 ease-in-out max-h-full w-full"
        />
        {#if content_count > 0}
          <div class="absolute inset-0 pointer-events-none">
            <div class="grid place-items-center w-full h-full text-sm">
              <div class="bg-white flex items-center gap-2 px-2.5 py-1 rounded shadow-md">
                <Icon icon="content" tiny />
                {content_count}
              </div>
            </div>
          </div>
        {/if}
        <Badge design="neutral-transparent" text={type_overlay} badgeClass="absolute top-2 left-2"></Badge>
        <Badge design="transparent" text={duration} badgeClass="absolute top-9 left-0.5"></Badge>
      </a>
      <div class="w-full h-1 bg-transparent absolute bottom-px inset-x-px">
        <div class="h-1 bg-yellow {progress} max-w-[calc(100%-2px)]"></div>
      </div>
    </div>
    <div class="absolute z-10 -left-3 top-0 h-full flex items-start">
      {#if editable}
        <div class="w-7 h-7 bg-white shadow-md p-1.5 hover:p-1 transition-all rounded handle">
          <Icon icon="handle" />
        </div>
      {/if}
    </div>
  </div>
  <div class="w-full md:w-1/3">
    <div class="min-w-[100%] md:min-w-[30%] pt-4 lg:pt-0">
      <a {href} class="font-bold font-serif !leading-tight tracking-tight group-hover:underline text-lg lg:text-2xl w-10/12">{title}</a>
      <div class="font-serif text-neutral-550 dark:text-neutral-350 !leading-snug text-base lg:text-lg">{subhead}</div>
      <div class="font-sans text-neutral-550 dark:text-neutral-350 text-sm sm:text-base"><SvelteMarkdown source={subeyebrow} /></div>
    </div>
  </div>
  <div class="w-full md:w-1/4">
    {#if learning_objectives}
      <div class="font-bold">{learning_objectives_header}</div>
      <SvelteMarkdown source={learning_objectives} />
    {/if}
  </div>
</div>

<style lang="postcss">
  .collectionsOpen {
    @apply translate-y-0 pointer-events-auto opacity-100;
  }
</style>
