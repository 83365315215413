<svelte:options customElement={{ tag: "bt-mobile-menu-button", shadow: "none" }} />

<script lang="ts">
  import { onMount } from 'svelte';

  export let targetID = '';

  let isClosed = true;
  let targetElement: Element | null;

  onMount(() => {
    targetElement = document.getElementById(targetID);
  });

  const handleClick = () => {
    if (targetElement) {
      isClosed = !isClosed;
      targetElement.classList.toggle('hidden', isClosed);
    } else {
      throw new Error('Mobile menu button could not find target element');
    }
  };
</script>

<button 
  type="button"
  class="inline-flex items-center justify-center p-2 text-neutral-400 rounded-md hover:text-neutral-100 hover:no-underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-700 focus:ring-neutral-100"
  aria-controls="mobile-menu"
  aria-expanded={`${isClosed ? 'false' : 'true'}`}
  aria-labelledby={`${targetID}-toggle-label`}
  on:click={handleClick}
>
  <span id={`${targetID}-toggle-label`} class="sr-only">
    {isClosed ? 'Open main menu':'Close main menu'}
  </span>
  {#if isClosed}
    <svg
      class="block w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  {:else}
    <svg
      class="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
    </svg>
  {/if}
</button>