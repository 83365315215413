<svelte:options customElement={{ tag: "bt-ratings-button", shadow: "none", }} />

<script lang="ts">
  import Icon from '../Icon.svelte'

  import { onMount, createEventDispatcher } from 'svelte';

  export let likeOrDislike: 'like' | 'dislike' = 'like';
  export let noninteractive: Boolean = false;
  export let rated: Boolean | string = false;
  export let text: string = "";
  export let postURL = "";
  export let isStorybook = false;

  const dispatch = createEventDispatcher();

  let csrfToken: string | any = '';
  onMount(() => {
    csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
  });

  // Declare vars for element and component bindings
  let icon = '';

  // Conditionally set styles and icon based on state
  let buttonClasses = '';
  $: {
    if (noninteractive && rated) {
      buttonClasses = 'rounded bg-sky-blue text-white'
    } else if (noninteractive && !rated) {
      buttonClasses = 'rounded bg-neutral-150 text-black'
    } else if (!noninteractive && rated) {
      buttonClasses = 'rounded-lg border border-neutral-350 bg-white text-sky-blue';
    } else if (!noninteractive && !rated) {
      buttonClasses = 'rounded-lg border border-neutral-350 bg-white text-neutral-550 hover:text-neutral-700'
    }
  }
  $: {
    if (rated) {
      icon = likeOrDislike === 'like' ? 'like_solid' : 'dislike_solid';
    } else {
      icon = likeOrDislike === 'like' ? 'like_outline' : 'dislike_outline';
    }
  }

  const postRating = (ratingValue: number) => {
    fetch(postURL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        rating: ratingValue,
      })
    }).then(response => {
      if (response.ok) {
        dispatch('rated', likeOrDislike)
      } else {
        const error = new Error(`Rating failed for ${postURL}`);
        throw error;
        return error;
      }
    })
  }

  const handleClick = (e) => {
    postRating(likeOrDislike === 'like' ? 5 : 1)
  }
</script>

<button type="button" 
  class="
    px-2 py-1
    {buttonClasses} 
    {rated ? 'rated' : ''} 
    {noninteractive ? 'noninteractive' : ''}
  "
  on:click={handleClick}
>
  <span>
    {#if icon}
      <Icon icon="{icon}"></Icon>
    {/if}
  </span>
  <span class="text-sm">{text}</span>
</button>

<style>
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: .25rem;
    cursor: pointer;
  }
  button.rated {
    cursor: default;
    pointer-events: none;
  }
  button.noninteractive {
    pointer-events: none;
  }
  div {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    text-align: left;
  }
  @media print {
    a {
      display: none !important;
    }
  }
</style>