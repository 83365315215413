<script lang="ts">
	import { Input } from '$comp/ui/input';
	import { Label } from '$comp/ui/label';
	import * as AlertDialog from '$comp/ui/alert-dialog/index';
	import { Button } from '$comp/ui/button';
  import { Textarea } from '$lib/components/ui/textarea/index.js';
  import Icon from '$shared/Icon.svelte';

  let totalCount: number = 0;
  let failedInstances: string[] = [];
  export let status: 'readyForUpload' | 'success' | 'failed' = 'readyForUpload';

  async function uploadUserFile() {
    const csvInput = document.getElementById('csv').files[0];
    const welcomeText = document.querySelector('textarea[name="welcomeText"]').value;

    let formData = new FormData();
    formData.append('file', csvInput);
    formData.append('welcome_text', welcomeText);

    if (csvInput !== null) {
      try {
        const response = await fetch('/users/bulk_upload', {
          method: 'POST',
          body: formData,
          headers: {
            'X-CSRF-Token': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content')
          }
        });

        if (response.ok) {
          const data = await response.json();
          totalCount = data.total_count;
          failedInstances = data.failed_instances;
          status = 'success';
        } else {
          status = 'failed';
        }
      } catch (error) {
        console.error(error);
      }
    }
	}
</script>

<AlertDialog.Root>
	<AlertDialog.Trigger asChild let:builder>
		<Button builders={[builder]} variant="outline"><Icon icon="upload" tiny svg_styling="mr-2" /> Bulk Upload</Button>
	</AlertDialog.Trigger>
	<AlertDialog.Content>
		<AlertDialog.Header>
			<AlertDialog.Title>Bulk Upload</AlertDialog.Title>
			<AlertDialog.Description>
        {#if status == "readyForUpload"}
          <div class="flex flex-col gap-4 pt-6">
            <div class="grid w-full items-center gap-1.5 pointer-events-auto">
              <Label for="csv">Click or drag file below</Label>
              <Input class="pt-1.5 h-16" id="csv" type="file" accept=".csv" />
            </div>
            <div>
              <a class="text-xs underline" href="/uploads/example.csv">Download a Sample CSV</a>
            </div>
            <div class="w-full space-y-2 pt-12">
              <Textarea
                class="h-32"
                name="welcomeText"
                placeholder="Add a customized welcome message to the invitation email. (optional)"
              />
            </div>
          </div>
        {:else if status == "success"}
          <div class="flex flex-col gap-4 pt-6">
            {totalCount} users have been successfully uploaded.
            {#if failedInstances.length > 0}
              <div>
                <p class="font-bold">The following users failed to upload. If they already exist, please update them in the table:</p>
                <ul>
                  {#each failedInstances as instance}
                    <li>{instance}</li>
                  {/each}
                </ul>
              </div>
            {/if}
          </div>
        {:else if status == "failed"}
          <div class="flex flex-col gap-4 pt-6">
            <p>Unable to upload users. Please check the file format and try again.</p>
          </div>
        {/if}
			</AlertDialog.Description>
		</AlertDialog.Header>
		<AlertDialog.Footer class="sm:justify-between !important">
      <span class="text-xs mt-2 opacity-70">
        {#if status == "readyForUpload"}Adding existing emails will update users' details.{/if}
      </span>
      <div>
        {#if status == "success" || status == "failed"}
          <AlertDialog.Action>Close</AlertDialog.Action>
        {:else}
			    <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
			    <Button on:click={uploadUserFile}>Invite Users</Button>
        {/if}
      </div>
		</AlertDialog.Footer>
	</AlertDialog.Content>
</AlertDialog.Root>
