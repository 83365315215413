<script lang="ts">
  import * as Turbo from "@hotwired/turbo";
  import { MoreHorizontal } from 'lucide-svelte';

  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import { Button } from '$lib/components/ui/button';

  export let row: any;

  function handleClickView(row: any) {
    Turbo.visit(row.url);
  }
</script>

<DropdownMenu.Root>
  <DropdownMenu.Trigger asChild let:builder>
    <Button variant="ghost" builders={[builder]} size="icon" class="relative w-8 h-8 p-0">
      <span class="sr-only">Open menu</span>
      <MoreHorizontal class="w-4 h-4" />
    </Button>
  </DropdownMenu.Trigger>
  <DropdownMenu.Content>
    <DropdownMenu.Item on:click={() => handleClickView(row)}>View on platform</DropdownMenu.Item>
  </DropdownMenu.Content>
</DropdownMenu.Root>
