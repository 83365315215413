<svelte:options customElement={{ tag: "bt-modal-toggle" }} />

<script lang="ts">
  import { isEqual } from 'lodash';
  import { onMount, getContext, setContext } from 'svelte';
  import { modalStore } from '$shared/modal/modalStore';
  import ActionButton from "$shared/action-button/ActionButton.svelte";

  type Base64EncodedString = string;
  export let opensModal: boolean = false;
  export let closesModal: boolean = false;
  export let modalData: Base64EncodedString | null = null;
  export let modalToToggle = '';
  export let buttonClasses = '';
  export let design = '';

  let modalDataConverted: Object;
  let isHidden: boolean = true;

  const convertBase64ToJson = (base64String: Base64EncodedString): Object => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/atob
    return JSON.parse(atob(base64String));
  }

  onMount(() => {
    isHidden = false;
    if (modalData) {
      modalDataConverted = convertBase64ToJson(modalData);
    }
  });

  const handleClick = () => {
    if (opensModal) {
      // Do nothing if trying to open same modal again
      if ($modalStore.modalName === modalToToggle) {
        if (isEqual($modalStore.modalData, modalDataConverted)) {
          return;
        }
      }
      modalStore.setName(modalToToggle);
      modalStore.setData(modalDataConverted);
    }
    if (closesModal) {
      modalStore.setName('');
      modalStore.setData({});
    }
  }
</script>

{#if design === 'action-button'}
  <ActionButton
    design="secondary"
    buttonClasses="{buttonClasses}"
    buttonStyles={isHidden ? 'display: none' : ''}
    on:click={handleClick}
  >
    <slot>
    </slot>
  </ActionButton>
{:else if design === 'action-button-text'}
  <ActionButton
    design="tertiary"
    buttonClasses="{buttonClasses}"
    buttonStyles={isHidden ? 'display: none' : ''}
    on:click={handleClick}
    >
      <slot>
      </slot>
  </ActionButton>
{:else if design === 'dropdown-button'}
  <button on:click={handleClick} class='dropdown' style={isHidden ? 'display: none' : 'display: block'}>
    <slot>
    </slot>
  </button>
{:else if design === 'fullscreen'}
  <button on:click={handleClick} class='fullscreen' style={isHidden ? 'display: none' : 'display: block'}>
    <slot>
    </slot>
  </button>
{:else}
  <button on:click={handleClick} class={buttonClasses || 'unstyled'} style={isHidden ? 'display: none' : 'display: block'}>
    <slot>
    </slot>
  </button>
{/if}

<style>
  button.unstyled {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  button.dropdown {
    font-family: "ibm-plex-sans";
    display: block;
    padding: theme('spacing.2');
    font-size: theme('fontSize.sm');
    color: theme('colors.neutral.700');
    background: transparent;
    border: 0;
    cursor: pointer;
    border-radius: theme('borderRadius.md');
    &:hover {
      background: theme('colors.neutral.200');
    }
  }
  button.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;  
  }
</style>