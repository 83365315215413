<script lang="ts">
	import * as AlertDialog from '$comp/ui/alert-dialog/index';
	import { Button } from '$comp/ui/button';
  import { data, selectedRowCount, checkedRows } from './stores';

  let status: 'successfullyRemoved' | 'failed' = 'successfullyRemoved';

  async function bulkRemoveUser() {
    // for each checked item, send a PUT request to the backend to expire the user
    // ideally move up and run the handleClickRemoveUser function from data-table-actions-users.svelte
    // but I couldn't figure out how to do that at the parent level (UserManagement wrapper) and pass the row
    // when I tried, I get an additional 3-dot menu at the buttons level
    // In the interest of getting this out, punting on a better, DRY implementation for now
    for (const [key] of Object.entries($checkedRows)) {
      try {
        const response = await fetch(`/users/${key}`, {
          method: "PUT",
          credentials: "same-origin",
          headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({"expire": true}),
        });

        if (response.ok) {
          status = 'successfullyRemoved';
          // this should be DRYed up along with the same code in the UserTable cols.push
          const index = $data.findIndex((item) => item.id == key);
						if (index >= 0) {
							// set status to expired on frontend
							$data[index].status = 'Expired';
							const date = new Date();
							const formattedDate = date.toLocaleDateString('en-CA', {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit',
							}).replace(/\//g, '-') + ' ';
							$data[index].expires_at = formattedDate;
							$data = $data;
						}
        } else {
          status = 'failed';
        }
      } catch (error) {
        console.error('Error removing user', error);
      }
    };
	}
</script>

<AlertDialog.Root>
	<AlertDialog.Trigger asChild let:builder>
		<Button
      builders={[builder]}
      variant="outline"
      disabled={$selectedRowCount == 0 ? true : false}
      on:click={bulkRemoveUser}
    >Bulk Remove {$selectedRowCount > 0 ? "(" + $selectedRowCount + ")" : ''}</Button>
	</AlertDialog.Trigger>
	<AlertDialog.Content>
		<AlertDialog.Header>
			<AlertDialog.Title>Remove Users</AlertDialog.Title>
			<AlertDialog.Description>
        {#if status == "successfullyRemoved"}
          <div class="flex flex-col gap-4 pt-6">
            {$selectedRowCount} users have been successfully removed.
          </div>
        {:else if status == "failed"}
          <div class="flex flex-col gap-4 pt-6">
            <p>Unable to remove users.</p>
          </div>
        {/if}
			</AlertDialog.Description>
		</AlertDialog.Header>
		<AlertDialog.Footer>
      <AlertDialog.Action>Close</AlertDialog.Action>
		</AlertDialog.Footer>
	</AlertDialog.Content>
</AlertDialog.Root>
