<svelte:options customElement={{ tag: "bt-editable", shadow: "none", }} />

<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte';
  import { default as Icon } from '@/components/shared/Icon.svelte';

  export let action = '';
  export let contents = '';
  export let contentStyle = '';
  export let editable: boolean = false;
  export let formStyle = false;
  export let model = '';
  export let placeholder = '';
  export let property = '';

  let editor: HTMLTextAreaElement;

  // timers for typing
  let closeTime = 300;
  let typeTimer = setTimeout(() => {}, 3000);
  var interval = setInterval(() => {}, 10);

  // event bubbling
  const dispatch = createEventDispatcher();

  $: contents, sendChange();

  function handleInput(event: any) {
    setSize();

    if (/[a-zA-Z0-9-_ ]/.test(contents)) {
      clearTimeout(typeTimer);
      closeTime = 300;
      clearInterval(interval);
      interval = setInterval(timerFunction, 10);
      typeTimer = setTimeout(() => finishTyping(event), 3000);
    } else {
      event.preventDefault();
      return;
    }
  }

  function timerFunction() {
    closeTime--;
    if (closeTime <= 0) {
      clearInterval(interval);
      closeTime = 300;
    }
  }

  function finishTyping(e: any) {
    let formContents = {};
    formContents[model] = {};
    formContents[model][property] = contents;

    fetch(action, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      },
      body: JSON.stringify(formContents),
    });

    editor.blur();
  }

  function getName() {
    return `${model}[${property}]`;
  }

  function sendChange() {
    dispatch('change', {
      contents: contents
    });
  }

  function setSize() {
    editor.style.height = '0px';
    editor.style.height = editor.scrollHeight + 'px';
  }

  function toggleEditor() {
    if (!editable) return;
    if (editor) formStyle = !formStyle;
    setTimeout(()=>{
      editor.focus();
    }, 0)
  }

  onMount(() => {
    if (editor) setSize();
  });
</script>

<div class="relative inline-block group w-full">
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    class="{contentStyle} {editable ? 'group-hover:pl-6 transition-all': ''}"
    class:hidden={formStyle}
    class:cursor-pointer={editable}
    on:click={toggleEditor}
    aria-label="Click to edit text"
  >
    {#if contents == ''}
      <span class="text-neutral-400">{placeholder}</span>
    {:else}
      {contents}
    {/if}
  </div>
  {#if editable}
    <textarea
      name={getName()}
      class={contentStyle}
      style={formStyle ? 'width:100%' : ''}
      class:border-neutral-300={formStyle}
      class:hidden={!formStyle}
      class:hover:border-neutral-300={formStyle}
      class:-ml-0={formStyle}
      placeholder={placeholder}
      on:blur={toggleEditor}
      on:input={handleInput}
      bind:this={editor}
      bind:value={contents}
    />
    <div
      class:hidden={formStyle}
      class="absolute top-0 left-0 h-full flex items-center justify-center opacity-0 transition-all pointer-events-none {editable ? 'group-hover:opacity-100':''}"
    >
      <Icon icon="edit" tiny />
    </div>
  {/if}
</div>

<style>
  textarea {
    resize: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  textarea::-webkit-scrollbar {
    display: none;
  }
</style>
