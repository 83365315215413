<script lang="ts">
	import { data, checkedRows } from './stores';
	import { Render, Subscribe, createRender, createTable } from 'svelte-headless-table';
	import {
		addExpandedRows,
		addHiddenColumns,
		addPagination,
		addSelectedRows,
		addSortBy,
		addTableFilter
	} from 'svelte-headless-table/plugins';

	import { Button } from '$lib/components/ui/button';
	import { Input } from '$lib/components/ui/input';
	import * as Table from '$lib/components/ui/table';
	import * as Tooltip from '$lib/components/ui/tooltip';
	import DataTableActions from './data-table-actions-users.svelte';
	import DataTableCheckbox from '$shared/data-table/data-table-checkbox.svelte';
	import DataTableColumnHeader from '$shared/data-table/data-table-column-header.svelte';
	import {
		getFormattedValue,
		noSortList,
		userMgmtColumnHeaders,
		userMgmtToolTips,
		userMgmtWithCompanyColumnHeaders,
		userFilterList
	} from '$shared/data-table/tableHelpers';

	export let companyOption: boolean = false;
	export let initialPageLength = 12;
	export let railsData: string;
	export let remoteData: any[] = JSON.parse(railsData);
	$data = remoteData;
	export let tableName = '';

	$: $selectedDataIds;

	const SLOTS = $$props.$$slots;
	const columnHeaders = companyOption ? userMgmtWithCompanyColumnHeaders : userMgmtColumnHeaders;

	function getColumnData() {
		let cols:any = [];
		cols.push(
			table.column({
				id: 'selected',
				header: '',
				accessor: 'key',
				cell: ({ row }, { pluginStates }) => {
					const { isSelected } = pluginStates.select.getRowState(row);

					return createRender(DataTableCheckbox, {
						isSelected
					});
				},
				plugins: {
					sort: {
						disable: true
					},
					filter: {
						exclude: true
					}
				}
			})
		);
		Object.keys($data[0]).forEach(function (key) {
			if (userFilterList.includes(key)) {
				cols.push(
					table.column({
						header: columnHeaders[key] ? columnHeaders[key] : key,
						accessor: key
					})
				);
			} else {
				cols.push(
					table.column({
						header: columnHeaders[key] ? columnHeaders[key] : key,
						accessor: key,
						plugins: {
							filter: {
								exclude: true
							}
						}
					})
				);
			}
		});
		cols.push(
			table.column({
				id: 'actions',
				header: '',
				accessor: 'expand',
				cell: ({ row }: any) => {
					return createRender(DataTableActions, {
						row: row.original,
					 }).on('remove-user', (e) => {
						// get id from event
						const id = e.detail;
						// find index in data array
						const index = $data.findIndex((row: any) => row.id === id);
						if (index >= 0) {
							// set status to expired on frontend
							$data[index].status = 'Expired';
							const date = new Date();
							const formattedDate = date.toLocaleDateString('en-CA', {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit',
							}).replace(/\//g, '-') + ' ';
							$data[index].expires_at = formattedDate;
							$data = $data;
						}
					});
				}
			})
		);
		return cols;
	}

	// default options for table object
	let tableOptions = {
		expand: addExpandedRows(),
		filter: addTableFilter({
			fn: ({ filterValue, value }) => value.toLowerCase().includes(filterValue.toLowerCase())
		}),
		hide: addHiddenColumns({
			initialHiddenColumnIds: ['id']}),
		page: addPagination({
			initialPageSize: initialPageLength
		}),
		select: addSelectedRows(),
		sort: addSortBy({
			toggleOrder: ['asc', 'desc']
		})
	};

	const table = createTable(data, tableOptions);
	let columns = table.createColumns(getColumnData());
	const { headerRows, pageRows, tableAttrs, tableBodyAttrs, pluginStates } =
		table.createViewModel(columns, { rowDataId: (row: any) => row.id });

	// ===== Plugin States =====
  const { hasNextPage, hasPreviousPage, pageIndex, pageCount } = pluginStates.page;
	const { selectedDataIds } = pluginStates.select;
	$: checkedRows.set($selectedDataIds)
	$: ({ filterValue } = pluginStates.filter);
</script>

<div class="space-y-4">
	<div class="flex md:flex-row flex-col gap-2 justify-between items-end">
		<div class="flex flex-col w-full gap-2">
			<p class="text-sm opacity-50 font-bold">{tableName}</p>
			<div class="flex gap-2">
				<Input
					class="md:max-w-sm w-full"
					placeholder="Filter..."
					type="text"
					bind:value={$filterValue}
				/>
			</div>
		</div>
		<div class="w-full flex items-center justify-end space-x-2">
			{#if SLOTS}
				<slot />
			{/if}
		</div>
	</div>
	<div class="rounded-md border border-neutral-200 bg-white">
		<Table.Root {...$tableAttrs}>
			<Table.Header class="sticky top-0 bg-white z-50">
				{#each $headerRows as headerRow}
					<Subscribe rowAttrs={headerRow.attrs()}>
						<Table.Row>
							{#each headerRow.cells as cell (cell.id)}
								<Subscribe attrs={cell.attrs()} let:attrs props={cell.props()} let:props>
									<Table.Head {...attrs} extraProps={props}>
										{#if !noSortList.includes(cell.id)}
											<DataTableColumnHeader {props}>
												<div class="text-left">
													<Tooltip.Root>
														<Tooltip.Trigger>
															<Render of={cell.render()} />
														</Tooltip.Trigger>
														<Tooltip.Content>
															<p>{userMgmtToolTips[cell.id]}</p>
														</Tooltip.Content>
													</Tooltip.Root>
												</div>
											</DataTableColumnHeader>
										{:else}
											<Tooltip.Root>
												<Tooltip.Trigger>
													<Render of={cell.render()} />
												</Tooltip.Trigger>
												<Tooltip.Content>
													<p>{userMgmtToolTips[cell.id]}</p>
												</Tooltip.Content>
											</Tooltip.Root>
										{/if}
									</Table.Head>
								</Subscribe>
							{/each}
						</Table.Row>
					</Subscribe>
				{/each}
			</Table.Header>
			<Table.Body {...$tableBodyAttrs}>
				{#each $pageRows as row, r (row.id)}
					<Subscribe rowAttrs={row.attrs()} let:rowAttrs rowProps={row.props()} let:rowProps>
						<tr
							class="depth-{row.id.split('>').length - 1}"
							class:selected={rowProps.select.selected}
							id={row.id}
							{...rowAttrs}
						>
							{#each row.cells as cell (cell.id)}
								<Subscribe attrs={cell.attrs()} let:attrs>
									<Table.Cell class="overflow-hidden text-ellipsis whitespace-nowrap" {...attrs}>
										{#if cell.render() == 'data-loading'}
											<div
												style="--delay: {r * 200}ms;"
												class="loading w-full bg-neutral-200 h-[20px] rounded-md"
											>
											</div>
										{:else}
											<Render of={getFormattedValue(cell.id, cell.render())} />
										{/if}
									</Table.Cell>
								</Subscribe>
							{/each}
						</tr>
					</Subscribe>
				{/each}
			</Table.Body>
		</Table.Root>
	</div>
	<div class="flex justify-between">
		<div class="">
      {Object.keys($selectedDataIds).length} of{' '}
      {$pageRows.length} selected
		</div>
		<div class="flex items-center justify-end space-x-2">
			<div class="">
        <p class="text-sm">Page {$pageIndex + 1} of {$pageCount}</p>
			</div>
			<Button
				variant="outline"
				size="sm"
				class="select-none"
        on:click={() => ($pageIndex = $pageIndex - 1)}
 				disabled={!$hasPreviousPage}>Previous</Button
			>
			<Button
				variant="outline"
				class="select-none"
				size="sm"
        disabled={!$hasNextPage}
 				on:click={() => ($pageIndex = $pageIndex + 1)}>Next</Button
			>
		</div>
	</div>
</div>

<style lang="postcss">
	.loading {
		background: linear-gradient(-45deg, #f3f3f3, #a5a5a5, #5c5c5c, #f3f3f3);
		opacity: 0.2;
		background-size: 400% 400%;
		animation: gradient 15s ease infinite;
		animation-delay: var(--delay);
	}

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		100% {
			background-position: 100% 50%;
		}
	}
</style>
