<svelte:options customElement={{ tag: "bt-curation-content", shadow: "none", }} />

<script lang="ts">
  // @ts-ignore
  import Sortable from 'sortablejs';
  import { onMount } from 'svelte';

  import CurationSection from './CurationSection.svelte';
  import CurationListItem from './CurationListItem.svelte';
  import Icon from '../shared/Icon.svelte';

  interface CuratedItem {
    id: number;
    content_count: number;
    curation_id: number;
    curatable_type: string;
    curatable_id: number;
    description: string;
    duration: string;
    editable: boolean;
    expert_name: string;
    expert_title: string;
    href: string;
    is_curation: boolean;
    learning_objectives: string;
    parent: number;
    position: number;
    progress: string;
    thumbnail_url: string;
    title: string;
    type_overlay: string;
    updateable: boolean;
    curated_items: CuratedItem[] | null;
  }

  export let contents: string;
  let contentsJSON:CuratedItem[] = JSON.parse(contents);
  export let curationId = 0;
  export let editable = contentsJSON[0].editable;

  export let addSectionText:string = "Add section";
  export let learningObjectivesHeader = 'Learning Objectives';
  export let sectionDescriptionPlaceholder = 'Click to edit section description';
  export let sectionDragPlaceholder = 'Drag content here using the hand icon.';
  export let sectionDurationLabel = 'Duration';
  export let sectionTitlePlaceholder = 'Click to edit section name';

  export let sectionActionsActionsLabel = 'Actions';
  export let sectionActionsMoveUpLabel = 'Move Section Up';
  export let sectionActionsMoveDownLabel = 'Move Section Down';
  export let sectionActionsDeleteLabel = 'Delete Section';

  export let sectionDeleteConfirmationText = 'Are you sure you want to delete this section, including the items within it?';
  export let sectionDeleteCompleteText = 'This section was deleted from your curation';

  let contentsList: HTMLDivElement;
  let currentElement: any;
  let parentSortable: any;

  onMount(async function () {
    parentSortable = Sortable.create(contentsList, {
      group: {
        name: 'contentsList',
        put: true,
        dataIdAttr: 'data-id',
        pull: function (event: any) {
          // only items with level 0 can be dragged out of the list
          var level = currentElement.getAttribute('data-level');
          if (level == 1) {
            return false;
          } else {
            return true;
          }
        }
      },
      scroll: true,
      filter: '.addnew', // prevent Add New bar from being sorted
      animation: 200,
      handle: '.handle',
      // Element dragging ended
      onEnd: function (/**Event*/ evt: any) {
        let itemEl = evt.item.getAttribute('data-id'); // dragged HTMLElement
        let fromCuration = evt.from.getAttribute('data-id');
        let toCuration = evt.to.getAttribute('data-curatable-id');
        let newIndex = evt.newIndex; // element's new index within new parent
        handleSort(itemEl, newIndex, fromCuration, toCuration);
      },
      onStart: function (evt: any) {
        currentElement = evt.item;
        parentSortable = parentSortable;
      },
      onChange: function(evt:any) {
        parentSortable = parentSortable;
      },
    });
  });

  async function addNewSection(parentCurationId: number, position: number) {
    const newSectionData = {
      "curation": {
        "parent_curation": parentCurationId,
        "position": position
      }
    }

    const response = await fetch(`/curations`, {
      method: 'POST',
      credentials: 'same-origin',
      // @ts-ignore
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // @ts-ignore
        'X-CSRF-Token': document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      },
      body: JSON.stringify(newSectionData)
    });

    if (response.ok) {
      updateParams("scrollTo", window.scrollY.toString());
      window.location.reload();
    }
  }


  function handleSort(
    itemEl: number,
    newIndex: number,
    fromCuration: number,
    toCuration: number
  ) {
    fetch(`/curations/${fromCuration}/curated_items/${itemEl}`, {
      method: 'PATCH',
      credentials: 'same-origin',
      // @ts-ignore
      headers: {
        'Content-Type': 'application/json',
        // @ts-ignore
        'X-CSRF-Token': document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      },
      body: JSON.stringify({
        position: newIndex,
        to_curation: toCuration
      })
    });
  }

  function updateParams(key: string, val: string) {
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, val);
    window.location.search = searchParams.toString();
  }

  onMount(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const scrollY: any = urlParams.get('scrollTo') || 0;
    if (scrollY !== 0) {
      scrollTo(0, scrollY);
    }
  })
</script>

{#if editable}
  <div class="addnew container mx-auto grid place-items-center pb-12">
    <button
      on:click={() => addNewSection(curationId, 0)}
      class="flex flex-row print:hidden justify-between space-x-2 text-sm items-center group rounded px-2 pl-3 py-1 mt-2 absolute bg-neutral-200 hover:bg-neutral-300 transition-colors"
    >
      <p>{addSectionText}</p>
      <Icon icon="plus_plain" tiny />
    </button>
    <hr class="w-full mt-2 border border-neutral-200 border-dashed" />
  </div>
{/if}

{#if contentsJSON}
  <div bind:this={contentsList} data-id={curationId} class="flex flex-col gap-12">
    {#each contentsJSON as subModule, i}
      {#if subModule.is_curation}
        <CurationSection
          {parentSortable}
          editable={editable}
          parentId={curationId}
          sectionContents={subModule.curated_items}
          sectionCuratableId={subModule.curatable_id}
          sectionDescription={subModule.description}
          sectionDuration={subModule.duration}
          sectionId={subModule.id}
          sectionTitle={subModule.title}
          sectionDescriptionPlaceholder={sectionDescriptionPlaceholder}
          sectionDragPlaceholder={sectionDragPlaceholder}
          sectionDurationLabel={sectionDurationLabel}
          sectionTitlePlaceholder={sectionTitlePlaceholder}
          sectionActionsActionsLabel={sectionActionsActionsLabel}
          sectionActionsMoveUpLabel={sectionActionsMoveUpLabel}
          sectionActionsMoveDownLabel={sectionActionsMoveDownLabel}
          sectionActionsDeleteLabel={sectionActionsDeleteLabel}
          sectionDeleteConfirmationText={sectionDeleteConfirmationText}
          sectionDeleteCompleteText={sectionDeleteCompleteText}
          on:move={e => {
            // on move, refresh parent sortable so child index is updated
            parentSortable = parentSortable;
          }}
        />
      {:else}
        <CurationListItem
          content_count={subModule.content_count}
          duration={subModule.duration}
          editable={editable}
          href={subModule.href}
          learning_objectives={subModule.learning_objectives}
          learning_objectives_header={learningObjectivesHeader}
          parent={subModule.curation_id}
          progress={subModule.progress}
          subhead={subModule.expert_name}
          subeyebrow={subModule.expert_title}
          title={subModule.title}
          thumbnail_url={subModule.thumbnail_url}
          type_overlay={subModule.type_overlay}
          updateable={subModule.updateable}
          id={subModule.id}
        />
      {/if}
    {/each}
  </div>
{:else}
  <div class="w-full max-w-xl mx-auto">
    <div
      class="bg-neutral-300 border border-neutral-400 aspect-video w-full rounded overflow-hidden"
    >
      <img class="w-full h-full object-cover" src="/img/demo.png" alt="" />
    </div>
  </div>
{/if}

{#if editable}
  <div id="addnew" class="addnew container mx-auto grid place-items-center py-6">
    <button
      on:click={() => addNewSection(curationId, contentsJSON.length)}
      class="flex flex-row print:hidden justify-between space-x-2 text-sm items-center group rounded px-2 pl-3 py-1 mt-2 absolute bg-neutral-200 hover:bg-neutral-300 transition-colors"
    >
      <p>{addSectionText}</p>
      <Icon icon="plus_plain" tiny />
    </button>
    <hr class="w-full mt-2 border border-neutral-200 border-dashed" />
  </div>
{/if}
