<svelte:options customElement={{ tag: "bt-icon", shadow: "none", }} />

<script>
  let icon_html;
  let size;

  export let icon = "";
  export let path_styling = "";
  export let svg_styling = "";
  export let tiny = false;

  function getIcon(icon) {
    switch (icon) {
      case "check":
        return icon_check();
        break;
      case "comment":
        return icon_comment();
        break;
      case "content":
        return icon_content();
        break;
      case "copy":
        return icon_copy();
        break;
      case "delete":
        return icon_delete();
        break;
      case "dislike_outline":
        return icon_dislike_outline();
        break;
      case "dislike_solid":
        return icon_dislike_solid();
        break;
      case "edit":
        return icon_edit();
        break;
      case "handle":
        return icon_handle();
        break;
      case "like_outline":
        return icon_like_outline();
        break;
      case "like_solid":
        return icon_like_solid();
        break;
      case "next":
        return icon_next();
        break;
      case "pdf":
        return icon_pdf();
        break;
      case "play":
        return icon_play();
        break;
      case "plus_plain":
        return icon_plus_plain();
        break;
      case "plus":
        return icon_plus();
        break;
      case "rarrow":
        return icon_rarrow();
        break;
      case "send":
        return icon_send();
        break;
      case "upload":
        return icon_upload();
        break;
      default:
        return `<!-- unknown icon ${icon} -->`;
        break;
    }
  }

  function getSize() {
    return tiny ? "w-4 h-4" : "w-6 h-6";
  }

  function getPathStyle() {
    return path_styling;
  }

  function getSVGStyle() {
    return [getSize(), svg_styling].join(" ");
  }

  function icon_check() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" title="Check" fill="none" viewBox="0 0 24 24"
        stroke-width="1.5" stroke="currentColor" class="${getSVGStyle()}">
        <path class="${getPathStyle()}" stroke-linecap="round" stroke-linejoin="round"
          d="M5 13l4 4L19 7" />
      </svg>
    `;
  }

  function icon_comment() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" title="Comment" fill="none" viewBox="0 0 24 24"
        stroke-width="1.5" stroke="currentColor" class="${getSVGStyle()}">
        <path class="${getPathStyle()}" stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9
          3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293
          3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0
          01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626
          2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012
          3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
          />
      </svg>
    `;
  }

  function icon_content() {
    return `
      <svg class="w-4 h-4 mt-px" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" title="Content">
        <path d="M1.33331 3.83331H13.3333M1.33331 7.83331H13.3333M1.33331 11.8333H9.33331M14.6666 12.1666L12.3333 13.8333V10.5L14.6666 12.1666Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `;
  }

  function icon_copy() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
        stroke-width="1.5" stroke="currentColor" class="${getSVGStyle()}" title="Copy">
        <path class="${getPathStyle()}" stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9
          15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0
          002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0
          00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0
          .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8
          0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8
          0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0
          0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125
          1.125h9.75c.621 0 1.125-.504
          1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75
          12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
          />
      </svg>
    `;
  }

  function icon_delete() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
        stroke-width="1.5" stroke="currentColor" class="${getSVGStyle()}" title="Delete">
        <path class="${getPathStyle()}" stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346
          9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107
          1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244
          2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108
          48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11
          48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964
          51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667
          48.667 0 00-7.5 0" />
      </svg>
    `;
  }

  function icon_dislike_outline() {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" title="Dislike" fill="none" viewBox="0 0 16 16" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
      <path d="M10.325 3H12v5c-.663 0-1.219.466-1.557 1.037a4.02 4.02 0 0 1-1.357 1.377c-.478.292-.907.706-.989 1.26v.005a9.031 9.031 0 0 0 0 2.642c.028.194-.048.394-.224.479A2 2 0 0 1 5 13c0-.812.08-1.605.234-2.371a.521.521 0 0 0-.5-.629H3C1.896 10 .99 9.102 1.1 8.003A19.827 19.827 0 0 1 2.18 3.215C2.45 2.469 3.178 2 3.973 2h2.703a2 2 0 0 1 .632.103l2.384.794a2 2 0 0 0 .633.103ZM14 2a1 1 0 0 0-1 1v6a1 1 0 1 0 2 0V3a1 1 0 0 0-1-1Z" />
    </svg>
    `;
  }

  function icon_dislike_solid() {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" title="Disliked" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
      <path d="M10.325 3H12v5c-.663 0-1.219.466-1.557 1.037a4.02 4.02 0 0 1-1.357 1.377c-.478.292-.907.706-.989 1.26v.005a9.031 9.031 0 0 0 0 2.642c.028.194-.048.394-.224.479A2 2 0 0 1 5 13c0-.812.08-1.605.234-2.371a.521.521 0 0 0-.5-.629H3C1.896 10 .99 9.102 1.1 8.003A19.827 19.827 0 0 1 2.18 3.215C2.45 2.469 3.178 2 3.973 2h2.703a2 2 0 0 1 .632.103l2.384.794a2 2 0 0 0 .633.103ZM14 2a1 1 0 0 0-1 1v6a1 1 0 1 0 2 0V3a1 1 0 0 0-1-1Z" />
    </svg>
    `;
  }

  function icon_edit() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" title="Edit" fill="none" viewBox="0 0 14 14"
        class="${getSVGStyle()}">
        <path class="${getPathStyle()}" fill="currentColor"
          d="M13.6144 3.88365L12.3538 5.14425C12.2253 5.27277 12.0175 5.27277 11.889 5.14425L8.85367 2.10897C8.72515 1.98045 8.72515 1.77263 8.85367 1.64411L10.1143 0.383511C10.6256 -0.127837 11.4569 -0.127837 11.971 0.383511L13.6144 2.02694C14.1285 2.53828 14.1285 3.36957 13.6144 3.88365ZM7.77081 2.72696L0.590004 9.90772L0.0102901 13.2301C-0.0690105 13.6786 0.322023 14.0669 0.770482 13.9903L4.0929 13.4079L11.2737 6.2271C11.4022 6.09858 11.4022 5.89076 11.2737 5.76224L8.23841 2.72696C8.10715 2.59844 7.89933 2.59844 7.77081 2.72696ZM3.39287 9.29246C3.24247 9.14206 3.24247 8.90143 3.39287 8.75103L7.604 4.53993C7.7544 4.38953 7.99503 4.38953 8.14543 4.53993C8.29583 4.69032 8.29583 4.93096 8.14543 5.08135L3.9343 9.29246C3.7839 9.44286 3.54327 9.44286 3.39287 9.29246ZM2.40571 11.5922H3.71827V12.5848L1.95452 12.8938L1.10409 12.0433L1.41309 10.2796H2.40571V11.5922Z"
        />
      </svg>
    `;
  }

  function icon_handle() {
		return `
      <svg title="Handle" width="100%" height="100%" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000">
        <path d="M7 10.5L4.99591 13.1721C4.41845 13.9421 4.47127 15.0141 5.1216 15.7236L8.9055 19.8515C9.28432 20.2647 9.81826 20.5 10.3789 20.5C11.4651 20.5 13.2415 20.5 15 20.5C17.4 20.5 19 19 19 16.5C19 16.5 19 16.5 19 16.5C19 16.5 19 9.64287 19 7.92859" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M16 8.49995C16 8.49995 16 8.37483 16 7.92852C16 5.6428 19 5.6428 19 7.92852" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M13 8.50008C13 8.50008 13 7.91978 13 7.02715M13 6.50008C13 6.50008 13 6.804 13 7.02715M16 8.50008C16 8.50008 16 8.37496 16 7.92865C16 7.70549 16 7.25031 16 7.02715C16 4.74144 13 4.74144 13 7.02715" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M13 8.50008C13 8.50008 13 7.91978 13 7.02715C13 4.74144 16 4.74144 16 7.02715C16 7.25031 16 7.70549 16 7.92865C16 8.37496 16 8.50008 16 8.50008" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M10 8.50005C10 8.50005 10 7.85719 10 6.50005C10 4.21434 13 4.21434 13 6.50005C13 6.50005 13 6.50005 13 6.50005C13 6.50005 13 6.80397 13 7.02713C13 7.91975 13 8.50005 13 8.50005" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M7 13.5001V6.50006C7 5.67164 7.67157 5.00006 8.5 5.00006V5.00006C9.32843 5.00006 10 5.55527 10 6.38369C10 6.42151 10 6.4603 10 6.50006C10 7.85721 10 8.50006 10 8.50006" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
			</svg>
		`;
	}

  function icon_like_outline() {
    return `
      <svg title="Like" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path d="M2.09 15a1 1 0 0 0 1-1V8a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1ZM5.765 13H4.09V8c.663 0 1.218-.466 1.556-1.037a4.02 4.02 0 0 1 1.358-1.377c.478-.292.907-.706.989-1.26V4.32a9.03 9.03 0 0 0 0-2.642c-.028-.194.048-.394.224-.479A2 2 0 0 1 11.09 3c0 .812-.08 1.605-.235 2.371a.521.521 0 0 0 .502.629h1.733c1.104 0 2.01.898 1.901 1.997a19.831 19.831 0 0 1-1.081 4.788c-.27.747-.998 1.215-1.793 1.215H9.414c-.215 0-.428-.035-.632-.103l-2.384-.794A2.002 2.002 0 0 0 5.765 13Z" />
      </svg>
    `;
  }

  function icon_like_solid() {
    return `
    <svg title="Liked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
      <path d="M2.09 15a1 1 0 0 0 1-1V8a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1ZM5.765 13H4.09V8c.663 0 1.218-.466 1.556-1.037a4.02 4.02 0 0 1 1.358-1.377c.478-.292.907-.706.989-1.26V4.32a9.03 9.03 0 0 0 0-2.642c-.028-.194.048-.394.224-.479A2 2 0 0 1 11.09 3c0 .812-.08 1.605-.235 2.371a.521.521 0 0 0 .502.629h1.733c1.104 0 2.01.898 1.901 1.997a19.831 19.831 0 0 1-1.081 4.788c-.27.747-.998 1.215-1.793 1.215H9.414c-.215 0-.428-.035-.632-.103l-2.384-.794A2.002 2.002 0 0 0 5.765 13Z" />
    </svg>
    `;
  }

  function icon_next() {
    return `
      <svg title="Next" xmlns="http://www.w3.org/2000/svg" class="${getSVGStyle()}"
        viewBox="0 0 20 20" fill="currentColor">
        <path class="${getPathStyle()}" d="M4.555 5.168A1 1 0 003 6v8a1 1 0 001.555.832L10 11.202V14a1 1 0
          001.555.832l6-4a1 1 0 000-1.664l-6-4A1 1 0 0010 6v2.798l-5.445-3.63z"
          />
      </svg>
    `;
  }

  function icon_pdf() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" title="PDF" fill="none" viewBox="0 0 24 24"
        stroke-width="1.5" stroke="currentColor" class="${getSVGStyle()}">
          <path class="${getPathStyle()}" stroke-linecap="round" stroke-linejoin="round" d="M19.5
            14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5
            7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621
            0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125
            1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
      </svg>
    `;
  }

  function icon_play() {
    return `
      <svg title="Play" class="drop-shadow-lg group/card:opacity-100" width="25" height="28"
        viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="${getPathStyle()}" d="M23.25 11.9623C24.5833 12.7321 24.5833 14.6566 23.25 15.4264L3
          27.1177C1.66667 27.8875 0 26.9253 0 25.3857V2.00298C0 0.463381
          1.66667 -0.498867 3 0.270933L23.25 11.9623Z" fill="white" />
      </svg>
    `;
  }

  function icon_plus() {
    return `
      <svg title="Plus" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute w-6 h-6 mt-6 rounded bg-neutral-350 justify-self-center">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
      </svg>
    `;
  }

  function icon_plus_plain() {
    return `
      <svg title="Plus" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="${getSVGStyle()}">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
      </svg>
    `;
  }

  function icon_rarrow() {
    return `
      <svg title="Right arrow" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
        viewBox="0 0 24 24" class="${getSVGStyle()}">
        <path class="${getPathStyle()}" fill-rule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5
          7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0
          11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0
          010-1.06z" clip-rule="evenodd" />
      </svg>
    `;
  }

  function icon_send() {
    return `
      <svg title="Send" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
        stroke-width="1.5" stroke="currentColor" class="${getSVGStyle()}">
        <path class="${getPathStyle()}" stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269
          3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999
          12zm0 0h7.5" />
      </svg>
    `;
  }

  function icon_upload() {
    return `
      <svg title="Upload" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
        stroke-width="1.5" stroke="currentColor" class="${getSVGStyle()}">
        <path class="${getPathStyle()}" stroke-linecap="round" stroke-linejoin="round"
          d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25
          2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
      </svg>
    `
  }
</script>

{#if icon}
  {@html getIcon(icon)}
{/if}
