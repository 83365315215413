import { writable, derived } from "svelte/store";

export const data = writable<any>(null);
export const editingUser = writable<any>(null);
export const checkedRows = writable<any>({});

export const selectedRowCount = derived(
  checkedRows,
  $checkedRows => {
    const keys = Object.keys($checkedRows);
    return keys.length;
  }
);
