<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { editingUser } from './stores'
  import { MoreHorizontal } from 'lucide-svelte';

  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import * as AlertDialog from '$lib/components/ui/alert-dialog/index';
  import { Button } from '$lib/components/ui/button';

  export let row: any;

  const dispatch = createEventDispatcher();
  let status = "";
  let open = false;

  function handleClickEditUser(row: any) {
    let first = row.full_name.split(' ')[0];
    let last = row.full_name.split(' ')[1];

    let formState = {
      id: row.id,
      company: row.company || '',
      firstName: first,
      lastName: last,
      email: row.email,
      expiresAt: row.expires_at,
      roles: row.roles,
    }

    $editingUser = formState
  }

  async function handleClickRemoveUser(row: any) {
    try {
      const response = await fetch(`/users/${row.id}`, {
        method: "PUT",
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify({"expire": true}),
      });

      if (response.ok) {
        dispatch("remove-user", row.id);
      }
    } catch (error) {
      console.error('Error removing user', error);
    }
  }

  async function handleClickResendInvite(row: any) {
    try {
      const response = await fetch(`/users/${row.id}`, {
        method: "PUT",
        credentials: "same-origin",
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify({"resendInvite": true}),
      });

      if (response.ok) {
        open = true;
        status = "resentSuccess";
      }
    } catch (error) {
      console.error('Error removing user', error);
    }
  }
</script>

{#if status == "resentSuccess"}
  <AlertDialog.Root bind:open>
    <AlertDialog.Content>
      <div>Email invitation resent to {row.email}</div>
      <AlertDialog.Footer>
        <AlertDialog.Cancel on:click={()=>{status = ""}}>Close</AlertDialog.Cancel>
      </AlertDialog.Footer>
    </AlertDialog.Content>
  </AlertDialog.Root>
{:else}
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button variant="ghost" builders={[builder]} size="icon" class="relative w-8 h-8 p-0">
        <span class="sr-only">Open menu</span>
        <MoreHorizontal class="w-4 h-4" />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content>
      <DropdownMenu.Item on:click={() => handleClickResendInvite(row)}>Resend Invite</DropdownMenu.Item>
      <DropdownMenu.Item on:click={() => handleClickEditUser(row)}>Edit User</DropdownMenu.Item>
      <DropdownMenu.Item on:click={() => handleClickRemoveUser(row)}>Remove User</DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
{/if}
