<script lang="ts">
	import { ArrowDown, ArrowUp, ChevronsUpDown } from 'lucide-svelte';

	import { Button } from '$comp/ui/button';

	export let props: {
		sort: {
			order: 'desc' | 'asc' | undefined;
			toggle: (event: Event) => void;
			clear: () => void;
			disabled: boolean;
		};
		filter: never;
	};
</script>

{#if !props.sort.disabled}
	<div class={'flex items-center'}>
    <Button
      variant="ghost"
      class="-ml-3 h-8 data-[state=open]:bg-accent"
      on:click={props.sort.toggle}
    >
      <slot />
      {#if props.sort.order === 'desc'}
        <ArrowDown class="ml-2 h-4 w-4" />
        {:else if props.sort.order === 'asc'}
        <ArrowUp class="ml-2 h-4 w-4" />
      {:else}
        <ChevronsUpDown class="ml-2 h-4 w-4" />
      {/if}
    </Button>
	</div>
{:else}
	<slot />
{/if}
