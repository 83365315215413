<svelte:options customElement={{ tag: "bt-eyebrow-button", shadow: "none", }} />

<script lang="ts">
  import Icon from '../Icon.svelte'

  export let text: string = 'Test';
  export let link: string = '';
  export let icon: string = '';
  export let eyebrow: string = '';
</script>

<a href="{link}" class="p-4 mt-8 bg-neutral-900 text-white hover:bg-transparent hover:text-neutral-900 border group">
  <div>
    <span class="text-xs uppercase text-neutral-150 group-hover:text-neutral-750">{eyebrow}</span>
    <span class="font-bold">{text}</span>
  </div>
  <span class="ml-4">
    {#if icon}
      <Icon id="{icon}" icon="{icon}"></Icon>
    {/if}
  </span>
</a>

<style>
  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  div {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    text-align: left;
  }
  @media print {
    a {
      display: none !important;
    }
  }
</style>